<template>
  <v-row no-gutters justify="center">
    <v-col cols="10">
      <table-products/>
    </v-col>
    <v-col cols="10">
      <table-balance/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    "table-products": () => import('../site/modules/shop/project/table-products'),
    "table-balance": () => import('../site/modules/shop/project/table-balance'),
  }
}
</script>

<style scoped>

</style>